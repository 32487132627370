import React, { useState } from "react";
import { Facebook, Instagram, LinkedIn, X } from "@mui/icons-material";
import { footerLinks } from "./constants";
import { Link } from "react-router-dom";
import { Logo } from "../assets";
import { toast } from "react-hot-toast";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);
    fetch("https://api.apispreadsheets.com/data/0q8ljvJuPjlKX88b/", {
      method: "POST",
      body: JSON.stringify({ data: { Email: email } }),
    }).then((res) => {
      if (res.status === 201) {
        toast.success("Subscribed successfully!");
        setLoading(false);
        setEmail("");
      } else {
        toast.error("Something went wrong!");
        setLoading(false);
        setEmail("");
      }
    });
  };
  return (
    <div className=" text-white ">
      <div className="container">
        <footer className="w-full flex flex-col justify-between items-center gap-6 lg:h-[508px] border-t border-white py-10 md:py-[80px]">
          <div className="w-full flex flex-col md:flex-row flex-wrap gap-3 justify-between">
            <div>
              <Link reloadDocument to="/">
                <img src={Logo} alt="logo" className="h-[60px]" />
              </Link>
              <div className="flex gap-2 mt-6">
                <a
                  href="https://www.linkedin.com/company/analytics-intelligence/"
                  target="_blank"
                  rel="noreferrer"
                  title="linkedin"
                >
                  <LinkedIn className="text-white" />
                </a>
                <a
                  href="https://www.instagram.com/analyticsintelligence/"
                  target="_blank"
                  rel="noreferrer"
                  title="instagram"
                >
                  <Instagram className="text-white" />
                </a>
                <a
                  href="https://twitter.com/_AnalyticsIntel"
                  target="_blank"
                  rel="noreferrer"
                  title="X"
                >
                  <X className="text-white" />
                </a>
                <a
                  href="https://www.facebook.com/Analyticsintel/"
                  target="_blank"
                  rel="noreferrer"
                  title="facebook"
                >
                  <Facebook className="text-white" />
                </a>
              </div>
            </div>
            {footerLinks.map((footer) => (
              <div key={footer.label} className="flex flex-col gap-[10px]">
                <h1 className="opacity-50 text-[13px] font-semibold">
                  {footer.label}
                </h1>
                <div className="flex flex-col gap-2">
                  {footer.links.map((f) =>
                    f.link.includes("#") || f.link.includes("mailto") ? (
                      <a href={f.link} key={f.label} className="text-sm">
                        {f.label}
                      </a>
                    ) : (
                      <Link to={f.link} key={f.label} className="text-sm">
                        {f.label}
                      </Link>
                    )
                  )}
                </div>
              </div>
            ))}
            <div
              className="w-full md:max-w-[256px] mt-3 lg:mt-auto flex flex-col gap-5"
              id="newsletter"
            >
              <h1 className="text-[14px] font-bold text-white">
                Sign Up For Our Newsletter
              </h1>
              <form
                className="flex w-full flex-col gap-5"
                onSubmit={(e) => handleSubscribe(e)}
              >
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Enter your email address"
                  className="w-full px-3 py-[10px] rounded-[4px] text-black  bg-white"
                />
                <button
                  type="submit"
                  className="text-white  bg-poppingBlue text-center py-[10px] text-xs h-10 rounded-[4px] px-3 uppercase"
                  disabled={loading}
                >
                  {loading ? "Subscribing..." : "Subscribe"}
                </button>
              </form>
            </div>
          </div>
          <p className="opacity-50 text-center">
            Copyright {new Date().getFullYear()} [AI] Analytics Intelligence
            Inc. All Rights Reserved
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
