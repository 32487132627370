import { Helmet, HelmetData } from "react-helmet-async";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { client } from "../../../client";
import { ArrowBack } from "@mui/icons-material";

const helmetData = new HelmetData({});

export default function SingleBlog() {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [allPosts, setAllPosts] = useState([]);
  const [relatedPosts, setRelatedPosts] = useState(null);
  const [readingTime, setReadingTime] = useState(0);
  const nav = useNavigate();
  const goBack = () => nav(-1);

  useEffect(() => {
    const calculateReadingTime = () => {
      const averageReadingSpeed = 200; // average 200 wpm reading
      const words = post?.body?.split(/\s+/).length;
      const time = Math.ceil(words / averageReadingSpeed);
      setReadingTime(time);
    };
    calculateReadingTime();
  }, [post?.body]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "Title"]{
      title,
      author,
      body,
      author_avatar,
      cover_photo,
      tags,
      published_date,
      featured_post,
      id,
      is_global,
      news
    }`
      )
      .then((data) => {
        setPost(data.filter((d) => d.is_global && d.id == id)[0]);
        setAllPosts(data.filter((d) => d.is_global && !d.news));
      });
  }, [id]);
  const match = (array1, arraysObj) => {
    const arrays = Object.values(arraysObj);
    return arrays.filter((arr) =>
      arr.tags.some((element) => array1.includes(element))
    );
  };
  const getRandomPosts = (postsArr, count) => {
    const shuffled = postsArr.slice().sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count) || [];
  };
  const shuffledPosts = allPosts.length > 0 ? getRandomPosts(allPosts, 3) : [];

  useEffect(() => {
    if (allPosts && post) {
      const related = match(post.tags, allPosts);
      setRelatedPosts(related);
    }
  }, [allPosts, post]);

  return (
    <>
      {post && (
        <Helmet helmetData={helmetData}>
          <title>{post.title}</title>
          <meta
            name="description"
            content={
              post?.body.split(" ").slice(0, 30).join(" ") +
              (post.body.split(" ").length > 10 ? "..." : "")
            }
          />
          <link rel="canonical" href={`/blog/${id}/`} />
          <meta property="og:title" content={post.title} />
          <meta
            property="og:description"
            content={
              post.body.split(" ").slice(0, 30).join(" ") +
              (post.body.split(" ").length > 10 ? "..." : "")
            }
          />
          <meta property="og:image" content={post.cover_photo} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={`/blog/${id}`} />
        </Helmet>
      )}
      <main className="single-blog-bg text-white pt-[120px] pb-[200px]   bg-[#03050B]">
        <button
          className="flex container mb-6 gap-3 font-[600]"
          onClick={goBack}
        >
          <ArrowBack />
          Back
        </button>
        {post && (
          <div className="container flex flex-col text-white pb-[50px] w-full max-w-[856px] mx-auto px-1">
            <img
              src={post.cover_photo}
              alt="blogimage"
              className="h-[300px] md:h-[650px] mx-auto rounded-lg"
            />
            <div className="flex justify-between items-center my-4">
              <div className="flex  gap-2 items-center">
                <p className="bg-[#091020] text-white text-[12px] leading-[150%] p-3 rounded-sm w-max">
                  {post?.tags[0]}
                </p>
                <p className="text-[14px] text-[rgba(255,255,255,0.70)] leading-[150%] ml-1">
                  {new Date(post?.published_date).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                </p>
              </div>
              <h3 className="text-poppingBlue text-sm font-bold">
                {readingTime} MINS READ
              </h3>
            </div>
            <div className="flex gap-[16px]">
              <img
                className="object-cover rounded-[50%] h-[48px] w-[48px] border border-[#01ADFF]"
                src={post.author_avatar}
                alt="avatar"
              />
              <div className="flex flex-col justify-between">
                <span>{post.author}</span>
                <span className="blurry">Author</span>
              </div>
            </div>
            <h1 className="text-[20px] sm:text-[26px] mt-[33px] leading-[150%] font-manrope text-white">
              {post.title}
            </h1>
            <div className=" flex flex-col mt-[32px] pb-[150px] gap-[20px] text-white">
              <div className=" body flex flex-col justify-center mx-auto  leading-[30px] blurry font-manrope ">
                <ReactMarkdown children={post.body} className="markdown" />
              </div>
              <div className="flex flex-col gap-[15px] md:w-[30%]">
                <h1 className="text-[18px] font-bold">Share</h1>
                <div className="flex gap-[10px]">
                  <FacebookShareButton
                    url={window.location.href}
                    quote={post.title}
                  >
                    <FacebookIcon size={50} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={window.location.href}
                    title={post.title}
                  >
                    <TwitterIcon size={50} />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={window.location.href}
                    title={post.title}
                  >
                    <WhatsappIcon size={50} />
                  </WhatsappShareButton>
                  <TelegramShareButton
                    url={window.location.href}
                    title={post.title}
                  >
                    <TelegramIcon size={50} />
                  </TelegramShareButton>
                </div>
              </div>
            </div>
            {relatedPosts &&
              relatedPosts.filter((p) => p.id != id).length > 0 && (
                <h1 className="text-[33px] mb-5 md:mb-[48px] text-center text-poppingBlue leading-[150%]">
                  Related Article
                </h1>
              )}
            <div className="flex flex-wrap gap-[30px]">
              {relatedPosts &&
                relatedPosts.map((p) =>
                  p.id != post.id ? (
                    <div
                      className="w-full max-w-[350px] mx-auto flex flex-col justify-between  p-3 pb-5 rounded-lg border border-[rgba(0,115,255,0.50)] bg-[#0B1B3F] flex-shrink-0"
                      key={p?.id}
                    >
                      <div>
                        <img
                          src={p.cover_photo}
                          alt="blog"
                          className="h-[200px] w-full rounded-lg"
                        />
                      </div>
                      <div className="flex flex-col my-2 gap-1">
                        <p className="bg-[#091020] text-white text-[8px] leading-[150%] p-1 rounded-sm w-max">
                          {p?.tags[0]}
                        </p>
                        <p className="text-[8px] text-[rgba(255,255,255,0.70)] leading-[150%] ml-1">
                          {new Date(p?.published_date).toLocaleDateString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                        </p>
                      </div>
                      <h1 className="text-white text-[23px] leading-[150%] mb-5">
                        {p.title}
                      </h1>
                      <Link
                        to={`/blog/${p.id}`}
                        className="text-white hover:border-b border-b-[#0073FF] text-[12px] font-[500] leading-[150%] w-max self-baseline justify-self-end"
                      >
                        Read Now
                      </Link>
                    </div>
                  ) : (
                    ""
                  )
                )}
            </div>
            <div className="w-full border-b border-b-[rgba(255,255,255,0.30)] my-10" />

            <style jsx="true">{`
              .swiper-button-next,
              .swiper-button-prev {
                color: #000;
              }
              .swiper-pagination-bullet-active {
                background-color: #000;
              }
              .body p {
                margin-bottom: 20px;
                color: rgba(255, 255, 255, 0.8);
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
              }
              .body h2 {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 20px;
              }
              .body img {
                display: flex;
                justify-content: center;
                align-item: center;
                margin: 20px auto;
              }
              .body a:hover {
                color: #0073ff;
              }
              .body a {
                text-decoration: underline;
              }
              .brand {
                height: 200px;
                width: 100%;
                object-fit: cover;
              }
              .speakers-header {
                font-weight: 500;
                text-transform: uppercase;
                margin-top: 1rem;
                color: #555;
                margin-bottom: 1rem;
              }
              .profile {
                display: flex;
                align-items: center;
                margin-bottom: 1.5rem;
              }
              .profile img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 1rem;
              }
              .profile h5 {
                font-size: 0.9rem;
              }
              .profile h6 {
                font-size: 0.8rem;
                font-weight: normal;
              }

              .other {
                text-align: center;
              }
              .banner .title {
                margin-bottom: 2rem;
                color: rgba(31, 53, 105, 1);
              }

              .card-base .content {
                padding: 0px;
                margin-top: 2rem;
                width: 100%;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                background-color: #fff;
                overflow: hidden;
              }

              .text-body {
                color: #000;
              }
              .text-body .btn-base {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
              }

              .card-base {
                display: flex;
                flex-direction: column;
                align-items: center;
              }

              .card-base h4 {
                font-size: 1.2rem;
                font-weight: 600;

                margin-bottom: 1rem;
              }
              .card-base a {
                color: inherit;
                text-decoration: none;
              }
              .card-base p {
                margin-top: 1rem;
                margin-bottom: 1rem;
              }

              .products {
                background-color: #fff;
                padding-top: 5rem;
                padding-bottom: 5rem;
                color: #1f3569;
              }
              .deliver {
                padding-top: 3rem;
                padding-bottom: 3rem;
              }
              .both {
                display: grid;
                grid-template-columns: 3fr 2fr;
                align-items: center;
                justify-content: center;
              }
              .both-media {
                padding: 2rem;
              }

              @media only screen and (max-width: 800px) {
                .both {
                  grid-template-columns: 1fr;
                }
              }
              .both-text p {
                font-size: 1.3rem;
              }
              .deliver h2 {
                margin-bottom: 1rem;
                font-size: 1.9rem;
              }
              .banner {
                margin-top: 85px;
                background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0.8),
                    rgba(0, 0, 0, 0.8)
                  ),
                  url(${post.cover_photo});
                padding-top: min(8rem, 10vw);
                padding-bottom: min(10rem, 15vw);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 25%;
                color: #fff;
              }
              .house {
                max-width: 700px;
              }
              .house p {
                font-size: 1rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
              }
              .btn-group button {
                padding: 1rem 4rem;
                margin-bottom: 1rem;
                font-size: 1.2rem;
              }
              .btn-group .btn-white {
                margin-right: 1rem;
              }
              .btn-base {
                padding: 1rem 3rem;
                margin-bottom: 1rem;
                font-size: 1.1rem;
                margin-top: 1rem;
              }
              // .bg-header{
              //   background: url('https://res.cloudinary.com/analytics-intelligence/image/upload/v1686853999/Line_5_1_e9mwkz.png');
              //   background-repeat: no-repeat;
              //   background-size: 100%;
              //   // background-position: center center;
              // }
            `}</style>
          </div>
        )}
        <div className="container mx-auto">
          {allPosts.length > 0 && (
            <h1 className="text-[33px] my-5 md:my-[48px] text-center text-poppingBlue leading-[150%]">
              More Articles
            </h1>
          )}
          <div className="flex flex-wrap gap-[30px]">
            {shuffledPosts.map((p) => (
              <div
                className="lg:w-[31.5%] md:w-[48%] max-w-[400px] w-full mx-auto flex flex-col justify-between  p-3 pb-5 rounded-lg border border-[rgba(0,115,255,0.50)] bg-[#0B1B3F] flex-shrink-0"
                key={p.id}
              >
                <div>
                  <img
                    src={p.cover_photo}
                    alt="blog"
                    className="h-[200px] w-full rounded-lg"
                  />
                </div>
                <div className="flex flex-col my-2 gap-1">
                  <p className="bg-[#091020] text-white text-[8px] leading-[150%] p-1 rounded-sm w-max">
                    {p.tags[0]}
                  </p>
                  <p className="text-[8px] text-[rgba(255,255,255,0.70)] leading-[150%] ml-1">
                    {new Date(p?.published_date).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </p>
                </div>
                <h1 className="text-white text-[23px] leading-[150%] mb-5">
                  {p.title}
                </h1>
                <Link
                  to={`/blog/${p.id}`}
                  className="text-white hover:border-b border-b-[#0073FF] text-[12px] font-[500] leading-[150%] w-max self-baseline justify-self-end"
                >
                  Read Now
                </Link>
              </div>
            ))}
          </div>
        </div>
      </main>
    </>
  );
}
