import React, { useEffect, useState } from "react";
// import Container from "../Container";
import { KeyboardArrowDown } from "@mui/icons-material";
import { navLinks } from "./constants";
import { Link } from "react-router-dom";
import { Logo } from "../assets";
import Hambuger from "./hamburger";

const HeaderNav = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [navbarBackground, setNavbarBackground] = useState("dark");
  const [showContent, setShowContent] = useState(null);
  const handleShowContent = (label) => {
    showContent === label ? setShowContent(null) : setShowContent(label);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = window.scrollY;
      const heroSectionHeight = 0;

      if (scrollHeight > heroSectionHeight) {
        setNavbarBackground("dark");
      } else {
        setNavbarBackground("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`z-[1000] bg-${navbarBackground}  fixed w-full top-0`}>
      <nav className="container w-full mx-auto flex justify-between py-3">
        <Link reloadDocument to="/">
          <img src={Logo} alt="logo" className="h-[60px]" />
        </Link>
        <div className="hidden md:flex  gap-6">
          {navLinks.map((link) => (
            <div className="relative group py-3" key={link.label}>
              <button
                type="button"
                className=" flex gap-1 items-center focus:outline-none hover:border-b-4  hover:border-b-poppingBlue  "
              >
                <p className="font-medium  text-white  hover:text-gray-200">
                  {link.label}
                </p>
                <KeyboardArrowDown className="text-white" />
              </button>
              <div className="hidden absolute top-12 right-0  bg-dark text-white  group-hover:flex flex-col justify-center items-center gap-4 w-[253px] rounded-lg shadow-2xl z-[100]  border-poppingBlue  ease-in-out p-3">
                {link.links.map((l) => (
                  <Link to={l.link} key={l.label} className="w-full">
                    <p className="w-full block p-2 text-center text-sm font-medium leading-[24px] hover:bg-inherit  hover:shadow-3xl  hover:border border-poppingBlue  text-white rounded-md">
                      {l.label}
                    </p>
                  </Link>
                ))}
              </div>
            </div>
          ))}
          <Link to="/company" className="py-3">
            <button
              type="button"
              className=" flex gap-1 items-center focus:outline-none hover:border-b-4  hover:border-b-poppingBlue "
            >
              <p className="font-medium  text-white  hover:text-gray-200">
                Company
              </p>
            </button>
          </Link>
          <Link
            to="/demo"
            className=" rounded-xl py-3 text-white text-center  px-3 border border-poppingBlue text-sm hover:text-poppingBlue hover:border-0 hover:border-b-4 hover:border-b-poppingBlue hover:rounded-none md:ml-2 h-max"
          >
            Request a Demo
          </Link>
        </div>
        <button
          type="button"
          className="md:hidden relative z-[200] px-2 cursor-pointer"
          onClick={() => setToggleMenu((prev) => !prev)}
          title="menu"
        >
          <Hambuger isOpen={toggleMenu} />
        </button>
        {toggleMenu && (
          <div
            className={`md:hidden ${
              toggleMenu
                ? "opacity-100 translate-x-0"
                : "opacity-0 -translate-x-full"
            } transition-all flex-col items-start  px-6 w-full max-w-[280px] sm:max-w-[300px] left-0 bg-dark shadow-xl  fixed h-[100vh] z-[200] top-0 mt-[70px]  duration-1000
           `}
          >
            <div className="md:hidden flex flex-col mt-[2rem] gap-7 justify-between">
              {" "}
              {navLinks.map((link) => (
                <div className="relative  py-3" key={link.label}>
                  <button
                    type="button"
                    className=" flex gap-1 items-center focus:outline-none hover:border-b-4  hover:border-b-poppingBlue  "
                    onClick={() => handleShowContent(link.label)}
                  >
                    <p className="font-medium  text-white  hover:text-gray-200">
                      {link.label}
                    </p>
                    <KeyboardArrowDown className="text-white" />
                  </button>
                  <div
                    className={`${
                      showContent === link.label ? "flex" : "hidden"
                    } absolute top-12 right-0  bg-dark text-white  flex-col justify-center items-center gap-4 w-[253px] rounded-lg shadow-2xl z-[100]  border-poppingBlue  ease-in-out p-3`}
                  >
                    {link.links.map((l) => (
                      <Link
                        to={l.link}
                        key={l.label}
                        onClick={() => {
                          setToggleMenu((prev) => !prev);
                          setShowContent(null);
                        }}
                        className="w-full"
                      >
                        <p className="w-full block p-2 text-center text-sm font-medium leading-[24px] hover:bg-inherit  hover:shadow-3xl  hover:border border-poppingBlue  text-white rounded-md">
                          {l.label}
                        </p>
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
              <Link
                to="/company"
                className="py-3"
                onClick={() => setToggleMenu((prev) => !prev)}
              >
                <button
                  type="button"
                  className=" flex gap-1 items-center focus:outline-none hover:border-b-4  hover:border-b-poppingBlue "
                >
                  <p className="font-medium  text-white  hover:text-gray-200">
                    Company
                  </p>
                </button>
              </Link>
              <Link
                onClick={() => setToggleMenu((prev) => !prev)}
                to="/demo"
                className=" rounded-xl py-3 text-white text-center  px-3 border border-poppingBlue text-sm hover:text-poppingBlue hover:border-0 hover:border-b-4 hover:border-b-poppingBlue hover:rounded-none md:ml-2 h-max"
              >
                Request a Demo
              </Link>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default HeaderNav;
